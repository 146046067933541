<template>

    <div>

    </div>
</template>

<script>
import router from "@/router";
import AuthDataService from "@/components/auth/AuthDataService";

export default {
    name: "Logout",
    methods: {
        logout() {
            AuthDataService.logout()
                .then((response) => {
                    localStorage.removeItem('authUser');
                    this.$store.state.isLoggedIn = false;
                    this.$store.state.user = null;
                    this.$store.state.token = null;
                    document.dispatchEvent(new Event('onLoggedOut'));
                    router.push("/login").catch(e => {});
                })
                .catch((errors) => {
                    console.log("Cannot log out")
                });
        }
    },
    mounted() {
        this.logout();
    }
}
</script>

<style scoped>

</style>
